import topic from '../topic';

export const rqtaAPI = {
    getRqtas: topic.qutaTopic + '/get_rqtas',
    getRqtaById: topic.qutaTopic + '/get_rqta_by_id',
    editRqta: topic.qutaTopic + '/edit_rqta',
    checkRqtaFlag: topic.qutaTopic + '/check_rqta_flag',
    get_quta_pdf_by_id: topic.pdfTopic + '/get_rqta_pdf_by_id',
    deleteRqtaByIds: topic.qutaTopic + '/delete_rqta_by_ids',
    getRqtaPushList: topic.qutaTopic + '/getRqtaPushList'
};
