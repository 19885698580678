<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getFequsNow()"
                 label-width="120px">
          <el-row>
            <el-col :md="8">
              <el-form-item label="报价单号:" prop="quta_no">
                <el-input size="small" v-model.trim="searchForm.quta_no" clearable
                          placeholder="请填写报价单号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="工厂打样单号:" prop="smpl_no">
                <el-input size="small" v-model.trim="searchForm.smpl_no" clearable
                          placeholder="请填写工厂打样单单号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="委托打样单号:" prop="requ_no">
                <el-input size="small" v-model.trim="searchForm.requ_no" clearable
                          placeholder="请填写委托打样单号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="样品名称:" prop="smpl_name">
                <el-input size="small" v-model.trim="searchForm.smpl_name" clearable
                          placeholder="请填写样品名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="打样报价人:" prop="smpl_name">
                <el-input size="small" v-model.trim="searchForm.quta_quoter_name" clearable
                          placeholder="请填写打样报价人"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="采购核价人:" prop="smpl_name">
                <el-input size="small" v-model.trim="searchForm.quta_pricer_name" clearable
                          placeholder="请填写采购核价人"></el-input>
              </el-form-item>
            </el-col>
            <!--            <el-col :md="8">-->
            <!--              <el-form-item label="打样报价人:" prop="quta_quoter">-->
            <!--                <el-select v-model="searchForm.quta_quoter" clearable filterable size="small">-->
            <!--                  <el-option v-for="item in stffUserList" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"></el-option>-->
            <!--                </el-select>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--            <el-col :md="8">-->
            <!--              <el-form-item label="采购核价人:" prop="quta_pricer">-->
            <!--                <el-select v-model="searchForm.quta_pricer" clearable filterable size="small">-->
            <!--                  <el-option v-for="item in stffUserList" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"></el-option>-->
            <!--                </el-select>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
          </el-row>
          <el-row></el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="单据状态:" prop="status">
                <el-select clearable size="small" v-model="searchForm.status" placeholder="请选择单据状态">
                  <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="核价日期:" prop="timeValue">
                <el-date-picker
                    v-model="searchForm.timeValue"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="small"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :md="8">
              <el-form-item label="录入人:" prop="stff_id">
                <el-select filterable v-model="searchForm.stff_id" placeholder="请选择录入人" size="small" clearable>
                  <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name"
                             :value="item.stff_id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="录入时间:" prop="timeValue1">
                <div class="block">
                  <el-date-picker
                      size="small"
                      v-model="searchForm.timeValue1"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getFequsNow()" class="vg_ml_16">
                  查询
                </el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                ><i class="el-icon-refresh-right"></i> 刷新
                </el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i>
          删除
        </el-button>
        <el-button type="danger" size="small" @click="backRqta()" :disabled="!btn.delete"><i class="el-icon-delete"></i>
          退报价单
        </el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
              class="vg_cursor"
              ref="multiTable"
              :data="tableData"
              @selection-change="handleSelectionChange"
              @select="selectRows"
              @select-all="selectRows"
              @row-dblclick="dbClickJp"
              v-loading="loadFlag"
              border
          >
            <el-table-column type="selection" width="48" align="center"/>
            <el-table-column label="报价单号" prop="quta_no"/>
            <el-table-column label="核价日期 " prop="quta_pridate" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.quta_pridate">{{ helper.toTimeDay(scope.row.quta_pridate) }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="工厂打样单号" prop="smpl_no"></el-table-column>
            <el-table-column label="委托打样单号" prop="requ_no"></el-table-column>
            <el-table-column label="样品名称" prop="smpl_name"></el-table-column>
            <el-table-column label="含税价格" prop="quta_total" align="right"></el-table-column>
            <el-table-column label="打样报价人" prop="quta_quoter_name" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.quta_quoter_name">{{ scope.row.quta_quoter_name }}</span>
                <span v-else class="vg_9f9a9a"> 暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="采购核价人" prop="quta_pricer_name" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.quta_pricer_name">{{ scope.row.quta_pricer_name }}</span>
                <span v-else class="vg_9f9a9a"> 暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="录入人" prop="stff_name" :show-overflow-tooltip="true" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.stff_name">{{ scope.row.stff_name }}</span>
                <span v-else class="vg_9f9a9a"> 暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="录入时间" prop="create_time" :show-overflow-tooltip="true" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.create_time">
                  {{ scope.row.create_time | formatDate }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="单据状态" :show-overflow-tooltip="true" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
                  {{ helper.getStatusName(scope.row.status).name }}
                </el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch"
                         ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get, post} from '@api/request';
import {stffAPI} from '@api/modules/staff';
import {rqtaAPI} from '@/api/modules/rqta';
import pubPagination from '@/components/common/pubPagination';
import helper from '@assets/js/helper.js';

export default {
  name: 'FequList',
  components: {
    pubPagination
  },
  data() {
    return {
      searchForm: {
        quta_no: null, //报价单号
        smpl_no: null, //打样单号
        requ_no: null, //委托打样单号
        smpl_name: null, // 样品名称
        quta_quoter: null, //打样报价人
        quta_pricer_name: null, //采购核价人
        quta_quoter_name: null, //打样报价人
        timeValue: {
          startTime: null,
          endTime: null
        }, //报价日期
        quta_pricer: null, //采购核价人
        stff_id: null, // 录入人
        timeValue1: {
          startTime: null,
          endTime: null
        }, //录入时间
        status: null //单据状态
      },
      loadFlag: true,
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      stffUserList: [],
      statusList: [
        {
          id: 0,
          label: '草拟'
        },
        {
          id: 1,
          label: '在批'
        },
        {
          id: 2,
          label: '生效'
        }
      ]
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (to.path === '/rqta_list' || from.path === '/rqta_edit') {
        this.initData();
      }
    }
  },
  filters: {
    // 时间转换
    formatDate(row) {
      return helper.toStringDate(row);
    },
    formatDate1(row) {
      return helper.toTimeDay(row);
    }
  },
  methods: {
    initData() {
      this.getRqtas();
      this.getDequStffList();
    },
    getRqtas() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      const timeNewVal1 = this.helper.getTime(this.searchForm.timeValue1);
      get(rqtaAPI.getRqtas, {
        quta_no: this.searchForm.quta_no, //报价单号
        smpl_no: this.searchForm.smpl_no, //打样单号
        requ_no: this.searchForm.requ_no, //委托打样单号
        smpl_name: this.searchForm.smpl_name, //样品名称
        quta_quoter: this.searchForm.quta_quoter, //打样报价人
        quta_quoter_name: this.searchForm.quta_quoter_name,
        quta_pricer: this.searchForm.quta_pricer, //打样 核价人
        quta_pricer_name: this.searchForm.quta_pricer_name,
        stff_id: this.searchForm.stff_id, //录入人
        status: this.searchForm.status, //单据状态
        start_time: timeNewVal.startTime, //核价日期
        end_time: timeNewVal.endTime,

        start_time2: timeNewVal1.startTime, //录入时间
        end_time2: timeNewVal1.endTime,
        page_no: this.currentPage //页码
      })
          .then(res => {
            if (res.data.code === 0) {
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total;
              this.btn = res.data.data.btn;
              setTimeout(() => {
                this.loadFlag = false;
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    // 获取委托人 打样报价人
    getDequStffList() {
      get(stffAPI.getAllStffsV1)
          .then(res => {
            if (res.data.code === 0) {
              this.stffUserList = res.data.data;
            }
          })
          .catch(() => {
          });
    },
    // 查询方法
    getFequsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getRqtas();
    },
    // // 刷新
    buttonRefresh() {
      this.$refs.searchForm.resetFields();
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getRqtas();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({type: 'warning', message: '请至少选择一条数据！'});
      } else {
        this.mBox();
      }
    },
    backRqta() {
      if (this.multiSelection.length === 0) return this.$message({type: 'warning', message: '请至少选择一条数据！'});
      this.$prompt('请填写退回原因!', '确定退回?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        inputValue: "",
        inputType: 'textarea',
        inputValidator: value => (value ? true : '请填写退回原因')
      }).then(({value}) => {
        this.$confirm('确定删除并退回？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
            .then(() => {
              this.delRow(true, value);
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              });
              this.$refs.multiTable.clearSelection();
            });
      });
    },
    // 删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.delRow();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
            this.$refs.multiTable.clearSelection();
          });
    },
    // 删除
    delRow(backSta, backText) {
      //backSta = 是否是退报价单 backText=退回原因
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.quta_id);
      });
      post(rqtaAPI.deleteRqtaByIds, {quta_id_list: ids, backSta: backSta, backText: backText})
          .then(res => {
            let mg = res.data.msg;
            if (res.data.code === 0) {
              let tp = 'success';
              this.$message({message: mg, type: tp});
              if (ids.length === this.tableData.length) {
                if (this.currentPage > 1) {
                  this.currentPage = this.currentPage - 1;
                  this.$refs.pubPagination.currentPage = this.currentPage;
                }
              }
              this.initData();
            } else {
              let tp = 'error';
              this.$message({message: mg, type: tp});
              this.initData();
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
            this.initData();
          });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      // const permId = this.$route.query.perm_id
      // this.jump('/quta_edit', {
      //     key: this.$UrlEncode.encode(JSON.stringify({
      //         perm_id: permId,
      //         form_id: row.quta_id
      //     }))
      // })
      const permId = this.$route.query.perm_id;
      console.log(permId),
          this.jump('/rqta_edit', {
            perm_id: permId,
            form_id: row.quta_id
          });
    },

    qutaPridateFormatter(row) {
      if (!row.quta_pridate) {
        return '暂无';
      }
      return helper.toTimeDay(row.quta_pridate);
    }
  }
};
</script>

<style scoped lang="scss"></style>
